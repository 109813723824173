<template>
  <div class="iot-history fadeIn">
    <van-nav-bar
      title="历史数据"
      left-text="返回"
      left-arrow
      @click-left="$router.go(-1)"
    >
      <template #right>
        <img @click="setParam" src="../../public/backImg/set.png" />
      </template>
    </van-nav-bar>

    <van-popup
      v-model="setParameter"
      position="left"
      :style="{ width: '80%', height: '100%' }"
      style="z-index: 1800 !important"
    >
      <div class="popup-form">
        <el-form ref="form" label-width="80px">
          <el-form-item label="项目：">
            <van-field :value="$route.query.projectName" readonly />
          </el-form-item>
          <el-form-item label="日期：">
            <el-date-picker
              size="mini"
              v-model="historyFrom.date"
              type="date"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              @change="getHistoryData"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="参数：">
            <el-select
              multiple
              collapse-tags
              :multiple-limit="5"
              v-model="historyFrom.paramTags"
              placeholder="请选择"
              @blur="getHistoryData"
            >
              <el-option
                v-for="item in options"
                :key="item.val"
                :label="item.text"
                :value="item.val"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </van-popup>
    <!-- 历史曲线 -->
    <div class="iot-history-data" v-show="checkHistory">
      <div id="historyData" ref="historyData"></div>
    </div>
  </div>
</template>
<script>
let that;
import * as echarts from "echarts";
export default {
  name: "History",
  data() {
    return {
      options: [],
      checkBtn: "数据表格",
      checkHistory: true,
      setParameter: false,
      historyFrom: {
        subOriginalId: this.$route.query.subOriginalId,
        projectId: this.$route.query.projectId,
        paramTags: [],
        date: "",
      },
      //设置时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  methods: {
    async getParam() {
      await this.$api.plc
        .getPlcSubOriginal(this.$route.query.subOriginalId)
        .then((res) => {
          if (res.code == 200) {
            this.options = [];
            let obj = res.data;
            for (let key in obj) {
              this.options.push({ text: key, val: obj[key] });
            }
            this.historyFrom.paramTags.push(this.options[0].val);
            this.getHistoryData();
          }
        });
    },
    getHistory(data, date, ref) {
      let option = {
        tooltip: {
          trigger: "axis",
        },
        toolbox: {
          feature: {
            dataView: {
              readOnly: true,
              optionToContent: function (opt) {
                var axisData = opt.xAxis[0].data;
                var series = opt.series;
                let nameTitle = "";
                series.forEach((item) => {
                  nameTitle += "<td style='width:180px'>" + item.name + "</td>";
                });
                var table =
                  '<table style="text-align:center"><tbody><tr>' +
                  "<td style='width:180px'>时间</td>" +
                  nameTitle +
                  "</tr>";
                for (var i = 0, l = axisData.length; i < l; i++) {
                  let valList = "";
                  series.forEach((item) => {
                    let newVal = "无";
                    item.data.forEach((it) => {
                      if (it[0] == axisData[i]) {
                        newVal = it[1];
                      }
                    });
                    valList += "<td style='width:180px'>" + newVal + "</td>";
                  });
                  table +=
                    "<tr>" +
                    "<td style='width:180px'>" +
                    axisData[i] +
                    "</td>" +
                    valList;
                  ("</tr>");
                }
                table += "</tbody></table>";
                return table;
              },
            },
          },
        },
        legend: {
          bottom: "1%",
          left: "10%",
          right: "10%",
          type: "plain",
          orient: "horizontal",
        },
        grid: {
          y: 50,
          y2: 80,
          x: 50,
          x2: 50,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: date,
        },
        yAxis: {
          type: "value",
          nameLocation: "end",
          boundaryGap: [0, 0],
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
          },
        ],
        series: data,
      };
      var chartDom = document.getElementById(ref);
      let myChart = echarts.init(chartDom);
      myChart.clear();
      myChart.setOption(option);
    },
    setCheckHistory() {
      if (that.checkHistory) {
        that.checkHistory = false;
        that.checkBtn = "数据表格";
      } else {
        that.checkHistory = true;
        that.checkBtn = "历史曲线";
      }
    },
    setParam() {
      that.setParameter = true;
    },

    //获取历史曲线数据
    getHistoryData() {
      this.$api.plc.gethistory(this.historyFrom).then((res) => {
        if (res.code == 200) {
          that.setHisturyBody(res.data);
        }
      });
    },
    setHisturyBody(data) {
      let Xdata = data.date;
      let series = [];
      data.data.forEach((ele) => {
        let dataItem = [];
        for (let key in ele.stream) {
          dataItem.push([key, ele.stream[key]]);
        }
        series.push({
          name: ele.name,
          type: "line",
          symbol: "emptyCircle",
          data: dataItem,
          connectNulls: true,
        });
      });
      that.getHistory(series, Xdata, "historyData");
    },
    // 默认获取当前时间
    getDate() {
      let date = new Date();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let dates = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

      that.historyFrom.date = date.getFullYear() + "-" + month + "-" + dates;
    },
  },

  created() {
    that = this;
    this.getDate();
  },
  mounted() {
    this.getParam();
  },
};
</script>
<style scoped>
.iot-history {
  width: 100%;
  height: 100%;
}
#historyData {
  width: 100vw;
  height: 90vh;
}
.popup-form {
  width: 100%;
  height: 100%;
  padding: 10px 8px;
  box-sizing: border-box;
  background-color: #fff;
  overflow-x: hidden;
}
.popup-form > div {
  border-radius: 12px;
  margin: 10px 0;
}
.el-date-editor {
  width: 100% !important;
  height: 48px !important;
}
::v-deep .el-input__inner {
  width: 100% !important;
  height: 100% !important;
  font-size: 13px;
}
.el-select {
  width: 100% !important;
  height: 48px !important;
}
::v-deep .el-select .el-input {
  width: 100% !important;
  height: 100% !important;
}
::v-deep .van-overlay {
  z-index: 1600 !important;
}
::v-deep .van-popup {
  z-index: 1800 !important;
}
.iot-history-title {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}
.iot-history-title > div {
  padding: 5px 10px;
  font-size: 14px;
  border: 1px solid #999;
  border-radius: 5px;
}
.iot-history-list {
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
}
.el-form-item {
  background-color: #fff;
}
::v-deep .el-form-item .el-form-item__label {
  line-height: 48px !important;
}
.switch {
  display: flex;
}
.switch > div {
  margin: 2px 5px;
}
::v-deep .cell {
  text-align: center !important;
}
</style>
